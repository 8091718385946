.rbc-date-cell{
  font-size: 16px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  pointer-events: none;
}

.rbc-date-cell.rbc-now{
  color:#020202de !important;
}

.rbc-month-header{
  background-color: #2f4554;
  border-radius: 5px 5px 0px 0px;
  color: white;
}


.rbc-date-cell.rbc-now{
  color: white
}

.rbc-row-segment{
  padding: 10px;
}

.rbc-row-segment{
  padding: 0px 7px;
}

.rbc-event{
  /* background-color: #ffff0000; */
  background-color:#00b7c4;
  padding-left: 10%;
  padding-top: 7px;
  padding-bottom: 7px
  /* text-align: center; */

 /*  color: black; */
}

.rbc-event:hover{
  border-color: #2f4554;
  background-color: #2f4554;
}

.rbc-event.rbc-selected{
  border-color: #2f4554;
  background-color: #2f4554;
}


/* .rbc-today:hover{
  background-color: cyan;
} */

.rbc-header{
  font-size: 14.4px;
  text-transform: capitalize;
  padding: 15px 0px
}

.rbc-month-view{
  border-radius: 5px;
}

.rbc-off-range-bg{
  background-color: #ffff0000;
  pointer-events: none;
  cursor:none;
}

.rbc-off-range{
  color: #DADADA;
  pointer-events: none; 
}


.rbc-header + .rbc-header{
  border-left: none;
}

// Sombras
/* .rbc-calendar{
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: white;
} */

// Sin borde
/* .rbc-month-view{
  border-color: transparent !important;
} */

