.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
    background-color: #2f4554;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected {
    background-color: #2f4554;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  color: white !important;
  font-size: 22px !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: white !important;
}

/* .Mui-selected {
  background-color: #00b7c4 !important;
} */

.MuiTypography-h5{
  font-size: 1rem !important;
  font-weight: bold !important;
}

/* .MuiMonthPicker-root {
  width: 200px !important;
  margin: 0;
}

.MuiCalendarPicker-root{
  width: 220px !important;
  margin: 0 !important;
} */
