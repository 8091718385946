.clickeable{
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
  width: 135px;
  background-color: #e7e7e7;
}

.clickeable:hover{
  background-color: rgb(0, 183, 196, 0.15);
}

.border-left-header{
  border-left: 1px solid #fff;
}

.border-right-header{
  border-right: 1px solid #fff;
}

.cell-total{
  color: #01b7c4;
  font-weight: bold;
}

.group-header{
  background-color: #2f4554 !important;
}

.cell-font{
  font-size: 12px;
}