/* .Mui-selected {
  background-color: #00b7c4 !important;
} */

.MuiPickersBasePicker-pickerView {
  min-height: 210px ! important;
  height: 310px !important;
}

.MuiPickersMonth-root{
  height: 40px !important;
}

.MuiPickersMonthSelection-container{
  margin: 0px 10px;
}

.MuiPickersMonth-monthSelected {
  color: white !important;
  background-color:#00b7c4;
  border-radius: 25px;
}

.MuiOutlinedInput-adornedEnd{
  padding-right: 0px !important;
}


