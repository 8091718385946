.popover-container {
  position: relative;
  display: inline-block;
  z-index: 9999 !important;
}

.popover-trigger {
  background-color: #2F4554;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 9999 !important;
}

.popover-content {
  position: absolute;
  bottom: 100%;
  transform: translateX(-90%);
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-inline: 10px;
  padding-block: 3px;
  z-index: 9999 !important;
  white-space: nowrap;
}