.leyenda::before {
  position: absolute;
  font-size: 14px;
  color: #2C4352;
  font-weight: bold;
  top: -24px;
  content: var(--content);
  white-space: nowrap;
}

.leyenda-plan-semanal {
  --content: "Real / Plan";
}

.leyenda-progreso-metros {
  --content: "Hoy";
}

.input-metros-avance-edit {
  width: 40px;
  background-color: transparent;
  text-align: center;
  padding-inline: 5px;
  border: none;
  outline: none;
  font-weight: bold;
}

.input-metros-avance-edit::selection {
  background-color: #2C4352;
}